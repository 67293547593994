@charset "UTF-8";
@font-face {
  font-family: MikadoBlack;
  src: url("/css/fonts/MikadoBlack.eot");
  src: url("/css/fonts/MikadoBlack.eot?#iefix") format("embedded-opentype"), url("/css/fonts/MikadoBlack.woff2") format("woff2"), url("/css/fonts/MikadoBlack.woff") format("woff"), url("/css/fonts/MikadoBlack.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.navbar {
  position: relative;
  font-weight: 600;
  font-family: 'Cabin';
  font-size: 22px;
}

@media (min-width: 2px) {
  .navbar {
    padding: 0;
  }
  .navbar .navbar-brand {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 10px;
  }
}

.navbar .navbar-brand {
  top: 8px;
}

.navbar .navbar-brand img {
  height: 70px;
}

@media (min-width: 992px) {
  .navbar .navbar-brand {
    top: 8px;
  }
  .navbar .navbar-brand img {
    height: 70px;
  }
}

@media (min-width: 1200px) {
  .navbar .navbar-brand {
    left: 0;
  }
  .navbar .navbar-brand img {
    height: 75px;
  }
}

.navbar .navbar-toggler {
  color: #0090d4;
  border-color: #0090d4;
  margin: 11px 0 12px;
}

@media (min-width: 992px) {
  .navbar .navbar-toggler {
    margin-top: -28px;
  }
}

.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 144, 212, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar .navbar-toggler .navbar-toggler-icon:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 86, 179, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar .navbar-toggler:focus {
  outline: 0px;
}

.navbar .navbar-collapse {
  margin: 32px 0 32px;
}

@media (min-width: 992px) {
  .navbar .navbar-collapse {
    margin: 0;
  }
}

.navbar .nav-item .nav-link {
  padding: 10px 10px;
}

@media (min-width: 992px) {
  .navbar .nav-item .nav-link {
    padding: 19px 10px;
  }
}

.navbar .nav-item .nav-link:hover {
  color: #0056b3;
  text-decoration: none;
}

.navbar .nav-item .nav-link:hover i.fas {
  color: #0056b3;
}

.navbar .nav-item.dropdown {
  border-radius: 0;
  border-style: none solid;
  border-color: transparent;
  border-width: 0px;
}

.navbar .nav-item.dropdown .nav-link {
  white-space: nowrap;
}

.navbar .nav-item.dropdown .nav-link .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 7px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

@media (max-width: 992px) {
  .navbar .nav-item.dropdown .nav-link .caret {
    width: 50px;
    height: 31px;
    border: none;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    margin-left: 0px;
    margin-top: -6px;
    float: right;
    padding: 5px 20px;
  }
  .navbar .nav-item.dropdown .nav-link .caret:before {
    content: "\f067";
  }
}

.navbar .nav-item.dropdown.show {
  border-color: #eaeaea;
}

@media (max-width: 992px) {
  .navbar .nav-item.dropdown.show .nav-link .caret:before {
    content: "\f068";
  }
}

@media (min-width: 992px) {
  .navbar .nav-item.dropdown {
    border-width: 1px;
  }
}

.navbar .nav-item .dropdown-menu {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  border-width: 0;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    background-color: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 7px 7px;
  }
  .navbar .nav-item .dropdown-menu .dropdown-item:last-child {
    border-radius: 0 0 7px 7px;
  }
}

@media (min-width: 768px) {
  .navbar {
    font-size: 16.94px;
  }
}

@media (min-width: 992px) {
  .navbar {
    font-size: 18.23px;
  }
}

@media (min-width: 1200px) {
  .navbar {
    font-size: 22px;
  }
}

.navbar * {
  color: #0090d4;
}

.navbar .dropdown-menu {
  padding: 0;
  margin: -1px 0 0;
  border-radius: 0;
  border-top-style: none;
  border-color: #eaeaea;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.navbar .dropdown-menu .dropdown-item {
  font-weight: 600;
  padding: 9px 18px;
  font-size: 20px;
}

@media (min-width: 768px) {
  .navbar .dropdown-menu .dropdown-item {
    padding: 15px 18px;
  }
}

.navbar .dropdown-menu .dropdown-item:hover, .navbar .dropdown-menu .dropdown-item:focus {
  color: #0056b3;
  text-decoration: none;
  background-color: transparent;
}

@media (min-width: 768px) {
  .navbar .dropdown-menu .dropdown-item {
    font-size: 15.40px;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-menu .dropdown-item {
    font-size: 16.57px;
  }
  .navbar .dropdown-menu .dropdown-item:hover, .navbar .dropdown-menu .dropdown-item:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #0090d4;
  }
}

@media (min-width: 1200px) {
  .navbar .dropdown-menu .dropdown-item {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }
}

body {
  font-family: 'Cabin', sans-serif;
  position: relative;
  background-color: #cce9f6;
}

#content-holder {
  background-color: #ffffff;
}

#content-holder section:nth-child(1) {
  padding-top: 20px;
}

section.header {
  z-index: 2;
  position: relative;
  background-color: #ffffff;
}

section.header .eb-shadow {
  position: absolute;
  bottom: 0px;
  height: 5px;
  width: 100%;
  box-shadow: 0px 3px 5px 0px rgba(158, 158, 158, 0.6);
}

section.intro {
  background-color: #fff6a6;
  min-height: 300px;
}

section.subheader {
  position: relative;
  background-color: #b4dfc4;
}

section.subheader .title {
  margin: 12px 0;
  text-align: center;
  position: relative;
}

section.subheader .title .eb-back {
  background-color: #ffffff;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 1px;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
  color: #0090d4;
}

section.subheader .title h1 {
  display: inline;
  margin: 0px;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #ffffff;
  font-size: 30px;
  line-height: 36px;
}

section.subheader .eb-shadow {
  position: absolute;
  bottom: 0px;
  height: 5px;
  width: 100%;
  box-shadow: 0px 3px 5px 0px rgba(158, 158, 158, 0.6);
}

body.movie section.item,
body.movie-item section.item,
body.music section.item,
body.music-item section.item,
body.puzzle section.item,
body.paint section.item,
body.games section.item,
body.print_material section.item {
  background-color: #fff6a6;
}

body.movie section.items,
body.movie-item section.items,
body.music section.items,
body.music-item section.items,
body.puzzle section.items,
body.paint section.items,
body.games section.items,
body.print_material section.items {
  background-color: #fff6a6;
  padding-bottom: 32px;
}

@media (min-width: 768px) {
  body.movie section.items,
  body.movie-item section.items,
  body.music section.items,
  body.music-item section.items,
  body.puzzle section.items,
  body.paint section.items,
  body.games section.items,
  body.print_material section.items {
    padding-bottom: 70px;
  }
}

body.movie section.items .title,
body.movie-item section.items .title,
body.music section.items .title,
body.music-item section.items .title,
body.puzzle section.items .title,
body.paint section.items .title,
body.games section.items .title,
body.print_material section.items .title {
  font-family: MikadoBlack;
  font-weight: 400;
  color: #f47920;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
}

body.movie section.items .container .row .square-box,
body.movie-item section.items .container .row .square-box,
body.music section.items .container .row .square-box,
body.music-item section.items .container .row .square-box,
body.puzzle section.items .container .row .square-box,
body.paint section.items .container .row .square-box,
body.games section.items .container .row .square-box,
body.print_material section.items .container .row .square-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 32px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

body.movie section.items .container .row .square-box:before,
body.movie-item section.items .container .row .square-box:before,
body.music section.items .container .row .square-box:before,
body.music-item section.items .container .row .square-box:before,
body.puzzle section.items .container .row .square-box:before,
body.paint section.items .container .row .square-box:before,
body.games section.items .container .row .square-box:before,
body.print_material section.items .container .row .square-box:before {
  content: "";
  display: block;
  padding-top: 100%;
}

body.movie section.items .container .row .square-content,
body.movie-item section.items .container .row .square-content,
body.music section.items .container .row .square-content,
body.music-item section.items .container .row .square-content,
body.puzzle section.items .container .row .square-content,
body.paint section.items .container .row .square-content,
body.games section.items .container .row .square-content,
body.print_material section.items .container .row .square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  padding: 20px;
}

body.movie section.items .container .row .square-content .sample,
body.movie-item section.items .container .row .square-content .sample,
body.music section.items .container .row .square-content .sample,
body.music-item section.items .container .row .square-content .sample,
body.puzzle section.items .container .row .square-content .sample,
body.paint section.items .container .row .square-content .sample,
body.games section.items .container .row .square-content .sample,
body.print_material section.items .container .row .square-content .sample {
  width: 100%;
  height: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

body.movie section.items .container .row .square-content .sample .opacity,
body.movie-item section.items .container .row .square-content .sample .opacity,
body.music section.items .container .row .square-content .sample .opacity,
body.music-item section.items .container .row .square-content .sample .opacity,
body.puzzle section.items .container .row .square-content .sample .opacity,
body.paint section.items .container .row .square-content .sample .opacity,
body.games section.items .container .row .square-content .sample .opacity,
body.print_material section.items .container .row .square-content .sample .opacity {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.8;
}

body.movie section.items .container .row .square-content .sample .play span,
body.movie-item section.items .container .row .square-content .sample .play span,
body.music section.items .container .row .square-content .sample .play span,
body.music-item section.items .container .row .square-content .sample .play span,
body.puzzle section.items .container .row .square-content .sample .play span,
body.paint section.items .container .row .square-content .sample .play span,
body.games section.items .container .row .square-content .sample .play span,
body.print_material section.items .container .row .square-content .sample .play span {
  display: inline-block;
  z-index: 2;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #ffffff;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
}

body.movie section.items .container .row .square-content .sample .play span i,
body.movie-item section.items .container .row .square-content .sample .play span i,
body.music section.items .container .row .square-content .sample .play span i,
body.music-item section.items .container .row .square-content .sample .play span i,
body.puzzle section.items .container .row .square-content .sample .play span i,
body.paint section.items .container .row .square-content .sample .play span i,
body.games section.items .container .row .square-content .sample .play span i,
body.print_material section.items .container .row .square-content .sample .play span i {
  color: #0090d4;
}

body.movie section.items .container .row .square-content .sample .download,
body.movie-item section.items .container .row .square-content .sample .download,
body.music section.items .container .row .square-content .sample .download,
body.music-item section.items .container .row .square-content .sample .download,
body.puzzle section.items .container .row .square-content .sample .download,
body.paint section.items .container .row .square-content .sample .download,
body.games section.items .container .row .square-content .sample .download,
body.print_material section.items .container .row .square-content .sample .download {
  z-index: 2;
}

body.movie section.items .container .row .square-content .sample .opacity,
body.movie section.items .container .row .square-content .sample .download,
body.movie-item section.items .container .row .square-content .sample .opacity,
body.movie-item section.items .container .row .square-content .sample .download,
body.music section.items .container .row .square-content .sample .opacity,
body.music section.items .container .row .square-content .sample .download,
body.music-item section.items .container .row .square-content .sample .opacity,
body.music-item section.items .container .row .square-content .sample .download,
body.puzzle section.items .container .row .square-content .sample .opacity,
body.puzzle section.items .container .row .square-content .sample .download,
body.paint section.items .container .row .square-content .sample .opacity,
body.paint section.items .container .row .square-content .sample .download,
body.games section.items .container .row .square-content .sample .opacity,
body.games section.items .container .row .square-content .sample .download,
body.print_material section.items .container .row .square-content .sample .opacity,
body.print_material section.items .container .row .square-content .sample .download {
  display: none;
}

body.movie section.items .container .row .square-content .sample:hover .opacity,
body.movie section.items .container .row .square-content .sample:hover .download,
body.movie-item section.items .container .row .square-content .sample:hover .opacity,
body.movie-item section.items .container .row .square-content .sample:hover .download,
body.music section.items .container .row .square-content .sample:hover .opacity,
body.music section.items .container .row .square-content .sample:hover .download,
body.music-item section.items .container .row .square-content .sample:hover .opacity,
body.music-item section.items .container .row .square-content .sample:hover .download,
body.puzzle section.items .container .row .square-content .sample:hover .opacity,
body.puzzle section.items .container .row .square-content .sample:hover .download,
body.paint section.items .container .row .square-content .sample:hover .opacity,
body.paint section.items .container .row .square-content .sample:hover .download,
body.games section.items .container .row .square-content .sample:hover .opacity,
body.games section.items .container .row .square-content .sample:hover .download,
body.print_material section.items .container .row .square-content .sample:hover .opacity,
body.print_material section.items .container .row .square-content .sample:hover .download {
  display: block;
}

body.movie section.items .container .row .square-content .paint,
body.movie-item section.items .container .row .square-content .paint,
body.music section.items .container .row .square-content .paint,
body.music-item section.items .container .row .square-content .paint,
body.puzzle section.items .container .row .square-content .paint,
body.paint section.items .container .row .square-content .paint,
body.games section.items .container .row .square-content .paint,
body.print_material section.items .container .row .square-content .paint {
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 21px;
  width: 42px;
  height: 42px;
  background-color: #ffffff;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: #63c29d;
  font-size: 21px;
  line-height: 42px;
  text-align: center;
}

body.music-item .items .music-container {
  margin: 50px 20px 0 0 !important;
}

@media (min-width: 992px) {
  body.music-item .items .music-container {
    margin: 50px 40px 0 0 !important;
  }
}

body.music-item .items .music-items-container {
  margin-top: 50px;
}

body.music-item .items #footerControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 25px 0 10px;
}

body.music-item .items #footerControls a {
  display: inline-block;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  margin: 0 7px 0 0;
  cursor: pointer;
}

@media (min-width: 992px) {
  body.music-item .items #footerControls a {
    margin: 0 25px 0 0;
  }
}

body.music-item .items #footerControls a.lgControl {
  width: 62px;
  height: 62px;
  border-radius: 31px;
}

body.music-item .items #footerControls a.lgControl i {
  color: #0090d4;
  font-size: 24px;
  line-height: 62px;
}

body.music-item .items #footerControls a i {
  color: #0090d4;
  font-size: 24px;
  line-height: 50px;
}

body.music-item .items #footerTitle {
  font-family: MikadoBlack;
  font-weight: 400;
  color: #f47920;
  font-size: 24px;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  text-align: center;
}

body.music-item .items .item {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 25px;
  margin-bottom: 13px;
}

body.music-item .items .item .left {
  flex-grow: 1;
  margin: 0 30px;
  min-width: 0;
}

body.music-item .items .item .left .jp-seek-bar {
  display: none;
  border-radius: 12px;
  background-color: #b2ddf2;
  height: 24px;
  margin: 13px 0;
  z-index: 100;
  overflow: hidden;
}

body.music-item .items .item .left .jp-play-bar {
  border-radius: 0px;
  background: none;
  background-color: #0090d4;
  width: 6px;
  height: 24px;
  cursor: pointer;
}

body.music-item .items .item .title {
  font-family: MikadoBlack;
  font-weight: 400;
  color: #f47920;
  font-size: 24px;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  text-align: left;
}

body.music-item .items .item .controls {
  color: #0090d4;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  margin: 0 30px 0 0;
  min-width: 0;
}

body.music-item .items .item .controls i {
  cursor: pointer;
}

body.music-item .items-jplayer .music-container {
  margin: 50px 40px 0 0 !important;
}

body.music-item .items-jplayer .music-items-container {
  margin-top: 50px;
}

body.music-item .items-jplayer .item {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 25px;
  margin-bottom: 13px;
}

body.music-item .items-jplayer .item .left {
  flex-grow: 1;
  margin: 0 30px;
  min-width: 0;
}

body.music-item .items-jplayer .item .left .jp-seek-bar {
  display: none;
  border-radius: 12px;
  background-color: #b2ddf2;
  height: 24px;
  margin: 13px 0;
  z-index: 100;
  overflow: hidden;
}

body.music-item .items-jplayer .item .left .jp-play-bar {
  border-radius: 0px;
  background: none;
  background-color: #0090d4;
  width: 6px;
  height: 24px;
  cursor: pointer;
}

body.music-item .items-jplayer .item .title {
  font-family: MikadoBlack;
  font-weight: 400;
  color: #f47920;
  font-size: 24px;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  text-align: left;
}

body.music-item .items-jplayer .item .controls {
  color: #0090d4;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  margin: 0 30px 0 0;
  min-width: 0;
}

body.music-item .items-jplayer .item .controls i {
  cursor: pointer;
}

body.games section.items .container .row .rectangle-box,
body.movie section.items .container .row .rectangle-box,
body.movie-item section.items .container .row .rectangle-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 32px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

body.games section.items .container .row .rectangle-box:before,
body.movie section.items .container .row .rectangle-box:before,
body.movie-item section.items .container .row .rectangle-box:before {
  content: "";
  display: block;
  padding-top: 76%;
}

body.games section.items .container .row .rectangle-content,
body.movie section.items .container .row .rectangle-content,
body.movie-item section.items .container .row .rectangle-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

body.games section.items .container .row .rectangle-content .image,
body.movie section.items .container .row .rectangle-content .image,
body.movie-item section.items .container .row .rectangle-content .image {
  flex-grow: 1;
  min-height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

body.games section.items .container .row .rectangle-content .image .play span,
body.movie section.items .container .row .rectangle-content .image .play span,
body.movie-item section.items .container .row .rectangle-content .image .play span {
  display: inline-block;
  z-index: 2;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #ffffff;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
}

body.games section.items .container .row .rectangle-content .image .play span i,
body.movie section.items .container .row .rectangle-content .image .play span i,
body.movie-item section.items .container .row .rectangle-content .image .play span i {
  color: #0090d4;
}

@media (max-width: 576px) {
  body.games section.items .container .row .rectangle-content .image .play span,
  body.movie section.items .container .row .rectangle-content .image .play span,
  body.movie-item section.items .container .row .rectangle-content .image .play span {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    font-size: 12px;
    line-height: 34px;
  }
}

body.games section.items .container .row .rectangle-content .title,
body.movie section.items .container .row .rectangle-content .title,
body.movie-item section.items .container .row .rectangle-content .title {
  font-family: MikadoBlack;
  font-weight: 400;
  color: #0090d4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
  font-size: 13px;
  line-height: 30px;
}

@media (min-width: 768px) {
  body.games section.items .container .row .rectangle-content .title,
  body.movie section.items .container .row .rectangle-content .title,
  body.movie-item section.items .container .row .rectangle-content .title {
    font-size: 18.48px;
    line-height: 38.50px;
  }
}

@media (min-width: 1200px) {
  body.games section.items .container .row .rectangle-content .title,
  body.movie section.items .container .row .rectangle-content .title,
  body.movie-item section.items .container .row .rectangle-content .title {
    font-size: 19.92px;
    line-height: 41.50px;
  }
}

body.games section.items .container .row .more-container,
body.movie section.items .container .row .more-container,
body.movie-item section.items .container .row .more-container {
  font-family: MikadoBlack;
  font-weight: 400;
  color: #0090d4;
  font-size: 19.92px;
  line-height: 41.50px;
  text-align: center;
  margin: 32px 0;
  cursor: pointer;
}

body.games section.items .container .row .video-container,
body.movie section.items .container .row .video-container,
body.movie-item section.items .container .row .video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin: 50px 0 0 0;
}

body.games section.items .container .row .video-container iframe,
body.games section.items .container .row .video-container object,
body.games section.items .container .row .video-container embed,
body.games section.items .container .row .video-container video,
body.movie section.items .container .row .video-container iframe,
body.movie section.items .container .row .video-container object,
body.movie section.items .container .row .video-container embed,
body.movie section.items .container .row .video-container video,
body.movie-item section.items .container .row .video-container iframe,
body.movie-item section.items .container .row .video-container object,
body.movie-item section.items .container .row .video-container embed,
body.movie-item section.items .container .row .video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.games section.items .container .row .rectangle-box:before {
  padding-top: 100%;
}

body.games section.items .container .row .rectangle-content {
  margin: 5px;
}

@media (min-width: 576px) {
  body.games section.items .container .row .rectangle-content {
    margin: 15px;
  }
}

@media (min-width: 768px) {
  body.games section.items .container .row .rectangle-content {
    margin: 15px;
  }
}

@media (min-width: 992px) {
  body.games section.items .container .row .rectangle-content {
    margin: 20px;
  }
}

body.games section.items .container .row .rectangle-content .image {
  background-size: contain;
}

body.games section.items .container .row .rectangle-content .title {
  margin: 0px;
  font-size: 15px;
  line-height: 1.2em;
}

@media (min-width: 576px) {
  body.games section.items .container .row .rectangle-content .title {
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  body.games section.items .container .row .rectangle-content .title {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  body.games section.items .container .row .rectangle-content .title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  body.games section.items .container .row .rectangle-content .title {
    font-size: 24px;
  }
}

body.puzzle section.items .container .row .square-box:before {
  padding-top: 83% !important;
}

body.puzzle section.items .container .row .square-box .square-content .sample {
  background-size: cover;
}

.eb-btn {
  display: inline-block;
  font-family: 'Cabin';
  font-weight: 600;
  background-color: #f47920;
  color: #ffffff !important;
  padding: 0px 20px;
  border-radius: 24px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 18px;
  line-height: 40px !important;
}

@media (min-width: 576px) {
  .eb-btn {
    font-size: 13.86px;
    line-height: 30.80px;
  }
}

@media (min-width: 768px) {
  .eb-btn {
    font-size: 13.86px;
    line-height: 30.80px;
  }
}

@media (min-width: 992px) {
  .eb-btn {
    font-size: 14.50px;
    line-height: 32.24px;
  }
}

@media (min-width: 1200px) {
  .eb-btn {
    font-size: 18px;
    line-height: 40px;
  }
}

.eb-btn:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #e73629;
}

.eb-btn i.far,
.eb-btn i.fal,
.eb-btn i.fas {
  color: #ffe600;
  padding-left: 10px;
  font-size: 19px;
  line-height: 21px;
}

@media (min-width: 576px) {
  .eb-btn i.far,
  .eb-btn i.fal,
  .eb-btn i.fas {
    font-size: 14.63px;
    line-height: 16.17px;
  }
}

@media (min-width: 768px) {
  .eb-btn i.far,
  .eb-btn i.fal,
  .eb-btn i.fas {
    font-size: 14.63px;
    line-height: 16.17px;
  }
}

@media (min-width: 992px) {
  .eb-btn i.far,
  .eb-btn i.fal,
  .eb-btn i.fas {
    font-size: 15.77px;
    line-height: 17.43px;
  }
}

@media (min-width: 1200px) {
  .eb-btn i.far,
  .eb-btn i.fal,
  .eb-btn i.fas {
    font-size: 19px;
    line-height: 21px;
  }
}

.eb-btn i.fab {
  line-height: 40px;
}

@media (min-width: 576px) {
  .eb-btn i.fab {
    line-height: 30.80px;
  }
}

@media (min-width: 768px) {
  .eb-btn i.fab {
    line-height: 30.80px;
  }
}

@media (min-width: 992px) {
  .eb-btn i.fab {
    line-height: 33.20px;
  }
}

@media (min-width: 1200px) {
  .eb-btn i.fab {
    line-height: 40px;
  }
}

.eb-btn.blue {
  background-color: #0090d4;
  color: #ffffff !important;
}

.eb-btn.blue:hover {
  text-decoration: none;
  background-color: #0056b3;
}

.eb-btn.yellow {
  background-color: #ffe600;
  color: #0090d4 !important;
}

.eb-btn.yellow i.fas {
  color: #f47920;
}

.eb-btn.yellow:hover {
  text-decoration: none;
  background-color: #e0ce02;
}

.eb-btn.white {
  background-color: #ffffff;
  color: #0090d4 !important;
}

.eb-btn.white i.far,
.eb-btn.white i.fas {
  color: #f47920;
}

.eb-btn.white:hover {
  text-decoration: none;
  background-color: #f2f2f2;
}

@media (max-width: 576px) {
  body.paint .eb-btn,
  body.print_material .eb-btn {
    font-size: 13.86px;
    line-height: 30.80px;
  }
  body.paint .eb-btn i.far,
  body.paint .eb-btn i.fal,
  body.paint .eb-btn i.fas,
  body.print_material .eb-btn i.far,
  body.print_material .eb-btn i.fal,
  body.print_material .eb-btn i.fas {
    font-size: 13.86px;
    line-height: 30.80px;
  }
}

NOT#backend #content-holder div:nth-child(1) section {
  background-color: #fff6a6 !important;
}

NOT.page-12 #content-holder section:nth-child(1),
NOT.sub-page #content-holder section:nth-child(1) {
  background-color: #fff6a6 !important;
}

.page-12 #content-holder section:nth-child(2) {
  background: linear-gradient(to bottom, #fff6a6 25%, transparent 0%) !important;
}

@media (min-width: 992px) {
  .page-12 #content-holder section:nth-child(2) {
    background: linear-gradient(to bottom, #fff6a6 50%, transparent 0%) !important;
  }
}

#content-holder section.template-11 {
  background-color: #fff6a6 !important;
}

#content-holder {
  padding-bottom: 20px;
  position: relative;
}

@media (min-width: 768px) {
  #content-holder {
    padding-bottom: 70px;
  }
}

#content-holder section.background-size-contain .prb_image1,
#content-holder section.background-size-contain .prb_image1,
#content-holder section.background-size-contain .prb_image1,
#content-holder section.background-size-contain .prb_image4 {
  background-size: contain !important;
}

#content-holder section.background-size-cover .prb_image1,
#content-holder section.background-size-cover .prb_image1,
#content-holder section.background-size-cover .prb_image1,
#content-holder section.background-size-cover .prb_image4 {
  background-size: cover !important;
}

#content-holder section.background-size-auto .prb_image1,
#content-holder section.background-size-auto .prb_image1,
#content-holder section.background-size-auto .prb_image1,
#content-holder section.background-size-auto .prb_image4 {
  background-size: auto !important;
}

#content-holder section .container > .row > div {
  margin: 20px 0;
}

@media (min-width: 576px) {
  #content-holder section .container > .row > div {
    margin: 20px 0;
  }
}

@media (min-width: 768px) {
  #content-holder section .container > .row > div {
    margin: 20px 0;
  }
}

@media (min-width: 992px) {
  #content-holder section .container > .row > div {
    margin: 20px 0;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container > .row > div {
    margin: 20px 0;
  }
}

#content-holder section .container > .row .prb_image1,
#content-holder section .container > .row .prb_image2,
#content-holder section .container > .row .prb_image3 {
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media (min-width: 576px) {
  #content-holder section .container > .row .prb_image1,
  #content-holder section .container > .row .prb_image2,
  #content-holder section .container > .row .prb_image3 {
    height: 100%;
  }
}

@media (max-width: 991px) {
  #content-holder section .container > .row .prb_image1,
  #content-holder section .container > .row .prb_image2,
  #content-holder section .container > .row .prb_image3 {
    min-height: auto !important;
  }
}

#content-holder section .container > .row .prb_text1,
#content-holder section .container > .row .prb_text2,
#content-holder section .container > .row .prb_text3,
#content-holder section .container > .row .prb_text4 {
  margin: 0px;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #ef413d;
  font-size: 28px;
  line-height: 34px;
}

@media (min-width: 576px) {
  #content-holder section .container > .row .prb_text1,
  #content-holder section .container > .row .prb_text2,
  #content-holder section .container > .row .prb_text3,
  #content-holder section .container > .row .prb_text4 {
    font-size: 21.56px;
    line-height: 26.18px;
  }
}

@media (min-width: 768px) {
  #content-holder section .container > .row .prb_text1,
  #content-holder section .container > .row .prb_text2,
  #content-holder section .container > .row .prb_text3,
  #content-holder section .container > .row .prb_text4 {
    font-size: 21.56px;
    line-height: 26.18px;
  }
}

@media (min-width: 992px) {
  #content-holder section .container > .row .prb_text1,
  #content-holder section .container > .row .prb_text2,
  #content-holder section .container > .row .prb_text3,
  #content-holder section .container > .row .prb_text4 {
    font-size: 23.20px;
    line-height: 28.22px;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container > .row .prb_text1,
  #content-holder section .container > .row .prb_text2,
  #content-holder section .container > .row .prb_text3,
  #content-holder section .container > .row .prb_text4 {
    font-size: 28px;
    line-height: 34px;
  }
}

#content-holder section .container > .row .prb_textarea1,
#content-holder section .container > .row .prb_textarea2,
#content-holder section .container > .row .prb_textarea3,
#content-holder section .container > .row .prb_textarea4,
#content-holder section .container > .row .prb_textarea5,
#content-holder section .container > .row .prb_textarea6 {
  overflow: hidden;
  margin: 0px;
  font-size: 18px;
  line-height: 28px;
}

@media (min-width: 576px) {
  #content-holder section .container > .row .prb_textarea1,
  #content-holder section .container > .row .prb_textarea2,
  #content-holder section .container > .row .prb_textarea3,
  #content-holder section .container > .row .prb_textarea4,
  #content-holder section .container > .row .prb_textarea5,
  #content-holder section .container > .row .prb_textarea6 {
    font-size: 13.86px;
    line-height: 21.56px;
  }
}

@media (min-width: 768px) {
  #content-holder section .container > .row .prb_textarea1,
  #content-holder section .container > .row .prb_textarea2,
  #content-holder section .container > .row .prb_textarea3,
  #content-holder section .container > .row .prb_textarea4,
  #content-holder section .container > .row .prb_textarea5,
  #content-holder section .container > .row .prb_textarea6 {
    font-size: 13.86px;
    line-height: 21.56px;
  }
}

@media (min-width: 992px) {
  #content-holder section .container > .row .prb_textarea1,
  #content-holder section .container > .row .prb_textarea2,
  #content-holder section .container > .row .prb_textarea3,
  #content-holder section .container > .row .prb_textarea4,
  #content-holder section .container > .row .prb_textarea5,
  #content-holder section .container > .row .prb_textarea6 {
    font-size: 14.91px;
    line-height: 23.20px;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container > .row .prb_textarea1,
  #content-holder section .container > .row .prb_textarea2,
  #content-holder section .container > .row .prb_textarea3,
  #content-holder section .container > .row .prb_textarea4,
  #content-holder section .container > .row .prb_textarea5,
  #content-holder section .container > .row .prb_textarea6 {
    font-size: 18px;
    line-height: 28px;
  }
}

#content-holder section .container > .row .prb_textarea1 p:last-child,
#content-holder section .container > .row .prb_textarea2 p:last-child,
#content-holder section .container > .row .prb_textarea3 p:last-child,
#content-holder section .container > .row .prb_textarea4 p:last-child,
#content-holder section .container > .row .prb_textarea5 p:last-child,
#content-holder section .container > .row .prb_textarea6 p:last-child {
  margin-bottom: 0;
}

#content-holder section .container > .row,
#content-holder section .container > .row li,
#content-holder section .container > .row a {
  font-family: Cabin;
  font-weight: 400;
  color: #292929;
  font-size: 18px;
  line-height: 28px;
}

@media (min-width: 576px) {
  #content-holder section .container > .row,
  #content-holder section .container > .row li,
  #content-holder section .container > .row a {
    font-size: 13.86px;
    line-height: 21.56px;
  }
}

@media (min-width: 768px) {
  #content-holder section .container > .row,
  #content-holder section .container > .row li,
  #content-holder section .container > .row a {
    font-size: 13.86px;
    line-height: 21.56px;
  }
}

@media (min-width: 992px) {
  #content-holder section .container > .row,
  #content-holder section .container > .row li,
  #content-holder section .container > .row a {
    font-size: 14.91px;
    line-height: 23.20px;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container > .row,
  #content-holder section .container > .row li,
  #content-holder section .container > .row a {
    font-size: 18px;
    line-height: 28px;
  }
}

#content-holder section .container > .row a,
#content-holder section .container > .row a:focus,
#content-holder section .container > .row a:hover {
  text-decoration: none;
  color: #0090d4;
  font-weight: 600;
}

#content-holder section .container > .row h1 {
  font-family: MikadoBlack;
  color: #ef413d;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
}

@media (min-width: 576px) {
  #content-holder section .container > .row h1 {
    font-size: 21.56px;
    line-height: 30.80px;
  }
}

@media (min-width: 768px) {
  #content-holder section .container > .row h1 {
    font-size: 21.56px;
    line-height: 30.80px;
  }
}

@media (min-width: 992px) {
  #content-holder section .container > .row h1 {
    font-size: 23.20px;
    line-height: 33.14px;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container > .row h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

#content-holder section .container > .row p {
  margin-bottom: 10px;
}

#content-holder section .container > .row b,
#content-holder section .container > .row strong {
  font-weight: 600;
}

#content-holder section.template-11 {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

#content-holder section.template-11 .container > .row {
  margin-top: 0px;
}

#content-holder section.template-11 .container > .row .prb_text1 {
  margin: 20px 0 0;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #f47920;
  font-size: 40px;
  line-height: 46px;
}

@media (min-width: 576px) {
  #content-holder section.template-11 .container > .row .prb_text1 {
    font-size: 30.80px;
    line-height: 35.42px;
  }
}

@media (min-width: 768px) {
  #content-holder section.template-11 .container > .row .prb_text1 {
    font-size: 30.80px;
    line-height: 35.42px;
  }
}

@media (min-width: 992px) {
  #content-holder section.template-11 .container > .row .prb_text1 {
    font-size: 33.20px;
    line-height: 38.18px;
  }
}

@media (min-width: 1200px) {
  #content-holder section.template-11 .container > .row .prb_text1 {
    font-size: 40px;
    line-height: 46px;
  }
}

#content-holder section.template-11 .container > .row .prb_textarea1 {
  margin: 20px 0 0 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
}

@media (min-width: 576px) {
  #content-holder section.template-11 .container > .row .prb_textarea1 {
    margin: 10px 0 0 30px;
    font-size: 15.40px;
    line-height: 26.18px;
  }
}

@media (min-width: 768px) {
  #content-holder section.template-11 .container > .row .prb_textarea1 {
    font-size: 15.40px;
    line-height: 26.18px;
  }
}

@media (min-width: 992px) {
  #content-holder section.template-11 .container > .row .prb_textarea1 {
    margin: 20px 0 20px 30px;
    font-size: 16.57px;
    line-height: 28.17px;
  }
}

@media (min-width: 1200px) {
  #content-holder section.template-11 .container > .row .prb_textarea1 {
    font-size: 20px;
    line-height: 34px;
  }
}

@media (max-width: 575px) {
  #content-holder section.template-11 .container > .row .prb_image1 {
    margin-top: -10px;
    height: 300px;
  }
}

#content-holder section.template-12 .container > .row .prb_text1,
#content-holder section.template-12 .container > .row .prb_text2 {
  margin: -3px 0 0;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #ef413d;
}

#content-holder section.template-13 .container > .row .prb_text1, #content-holder section.template-14 .container > .row .prb_text1 {
  margin: -3px 0 0;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #ef413d;
}

@media (max-width: 575px) {
  #content-holder section.template-13 .container > .row .box > .row.row-eq-height, #content-holder section.template-14 .container > .row .box > .row.row-eq-height {
    flex-direction: column-reverse;
  }
  #content-holder section.template-13 .container > .row .prb_image1, #content-holder section.template-14 .container > .row .prb_image1 {
    margin-bottom: 20px;
  }
}

#content-holder section.template-15 .container > .row .prb_text1, #content-holder section.template-21 .container > .row .prb_text1 {
  margin: -3px 0 0;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #ef413d;
}

@media (max-width: 575px) {
  #content-holder section.template-15 .container > .row .prb_image1, #content-holder section.template-21 .container > .row .prb_image1 {
    margin-bottom: 20px;
  }
}

#content-holder section.template-16 .container > .row .prb_text1,
#content-holder section.template-16 .container > .row .prb_text2,
#content-holder section.template-16 .container > .row .prb_text3, #content-holder section.template-22 .container > .row .prb_text1,
#content-holder section.template-22 .container > .row .prb_text2,
#content-holder section.template-22 .container > .row .prb_text3 {
  margin: -3px 0 0;
  font-family: MikadoBlack;
  font-weight: 400;
  color: #ef413d;
}

#content-holder section.template-16 .container > .row .prb_image1,
#content-holder section.template-16 .container > .row .prb_image2,
#content-holder section.template-16 .container > .row .prb_image3, #content-holder section.template-22 .container > .row .prb_image1,
#content-holder section.template-22 .container > .row .prb_image2,
#content-holder section.template-22 .container > .row .prb_image3 {
  margin: 30px 0;
  text-align: center;
  height: auto;
}

#content-holder section.template-16 .container > .row .prb_image1 img,
#content-holder section.template-16 .container > .row .prb_image2 img,
#content-holder section.template-16 .container > .row .prb_image3 img, #content-holder section.template-22 .container > .row .prb_image1 img,
#content-holder section.template-22 .container > .row .prb_image2 img,
#content-holder section.template-22 .container > .row .prb_image3 img {
  height: 100%;
  width: 100%;
}

#content-holder section.template-17 .container > .row .prb_text1,
#content-holder section.template-17 .container > .row .prb_text2 {
  margin: -3px 0 0;
}

@media (max-width: 575px) {
  #content-holder section.template-17 .container > .row .box > .row.row-eq-height {
    flex-direction: column-reverse;
  }
  #content-holder section.template-17 .container > .row .prb_image1 {
    margin-bottom: 20px;
  }
}

#content-holder section.template-18 .container > .row .prb_text2 {
  margin: -3px 0 0;
}

@media (max-width: 575px) {
  #content-holder section.template-18 .container > .row .box > .row.row-eq-height {
    flex-direction: column-reverse;
  }
  #content-holder section.template-18 .container > .row .prb_image2 {
    margin-bottom: 20px;
  }
}

#content-holder section.template-19 .container > .row .prb_text1 {
  margin: -3px 0 0;
}

@media (max-width: 575px) {
  #content-holder section.template-19 .container > .row .prb_image1 {
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) {
  #content-holder section.template-20 .eb-devider {
    height: 3px;
    width: 100%;
    background-color: #eaeaea;
    margin: 20px 0;
  }
}

#content-holder section.template-22 .container > .row .prb_image1,
#content-holder section.template-22 .container > .row .prb_image2,
#content-holder section.template-22 .container > .row .prb_image3 {
  margin: 20px 0 0;
}

#content-holder section.template-11.title-blue .container > .row .prb_text1, #content-holder section.template-12.title-blue .container > .row .prb_text1, #content-holder section.template-13.title-blue .container > .row .prb_text1, #content-holder section.template-14.title-blue .container > .row .prb_text1, #content-holder section.template-15.title-blue .container > .row .prb_text1, #content-holder section.template-16.title-blue .container > .row .prb_text1, #content-holder section.template-17.title-blue .container > .row .prb_text1, #content-holder section.template-18.title-blue .container > .row .prb_text1, #content-holder section.template-19.title-blue .container > .row .prb_text1, #content-holder section.template-20.title-blue .container > .row .prb_text1, #content-holder section.template-21.title-blue .container > .row .prb_text1, #content-holder section.template-22.title-blue .container > .row .prb_text1, #content-holder section.template-23.title-blue .container > .row .prb_text1 {
  color: #0090d4;
}

#content-holder .box {
  min-width: 100%;
}

#content-holder .box-white .box {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  padding: 20px;
}

@media (min-width: 576px) {
  #content-holder .box-white .box {
    padding: 40px;
  }
}

#content-holder .box-yellow .box {
  background-color: #fff6a6;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  padding: 20px;
}

@media (min-width: 576px) {
  #content-holder .box-yellow .box {
    padding: 40px;
  }
}

#content-holder section .container .row .nijsbrief {
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  height: 100%;
  background-color: #d9efe1;
}

#content-holder section .container .row .nijsbrief .content-wrapper {
  align-items: flex-start;
  margin: 20px;
}

@media (min-width: 768px) {
  #content-holder section .container .row .nijsbrief .content-wrapper {
    margin: 30.80px;
  }
}

@media (min-width: 992px) {
  #content-holder section .container .row .nijsbrief .content-wrapper {
    margin: 33.14px;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container .row .nijsbrief .content-wrapper {
    margin: 40px;
  }
}

#content-holder section .container .row .nijsbrief .content-wrapper .prb_text2 {
  color: #0090d4;
  margin-bottom: 20px;
}

#content-holder section .container .row .nijsbrief .content-wrapper .text {
  font-weight: 600;
}

#content-holder section .container .row .nijsbrief .content-wrapper .image {
  height: 100%;
  max-width: 100%;
  margin: 0 0 0 -15px;
  text-align: center;
}

#content-holder section .container .row .nijsbrief .content-wrapper .image img {
  width: 100%;
}

@media (min-width: 576px) {
  #content-holder section .container .row .nijsbrief .content-wrapper .image img {
    width: 100%;
  }
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions {
  margin: 30px 0 0px;
  text-align: left;
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions .form {
  overflow: hidden;
  position: relative;
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions .form input[name="email"] {
  border-radius: 20px;
  border-width: 0px;
  padding: 0px 50px 0px 20px;
  width: 100%;
  font-size: 17px;
  line-height: 40px;
  color: #949494;
}

@media (min-width: 576px) {
  #content-holder section .container .row .nijsbrief .content-wrapper .actions .form input[name="email"] {
    font-size: 13.09px;
  }
}

@media (min-width: 768px) {
  #content-holder section .container .row .nijsbrief .content-wrapper .actions .form input[name="email"] {
    font-size: 13.09px;
  }
}

@media (min-width: 992px) {
  #content-holder section .container .row .nijsbrief .content-wrapper .actions .form input[name="email"] {
    font-size: 14.11px;
  }
}

@media (min-width: 1200px) {
  #content-holder section .container .row .nijsbrief .content-wrapper .actions .form input[name="email"] {
    font-size: 17px;
  }
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions .form input[name="email"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949494;
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions .form .submit {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 32px;
  height: 32px;
  background-color: #0090d4;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  cursor: pointer;
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions .form .submit i.far {
  color: #ffffff;
}

#content-holder section .container .row .nijsbrief .content-wrapper .actions .form .submit:hover {
  background-color: #0056b3;
}

#content-holder .template-box-5 {
  height: 100% !important;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#content-holder .template-box-5 .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100%;
  min-height: 320px;
  position: relative;
}

@media (min-width: 576px) {
  #content-holder .template-box-5 .content-wrapper {
    min-height: 250px;
  }
}

@media (min-width: 768px) {
  #content-holder .template-box-5 .content-wrapper {
    min-height: 300px;
  }
}

#content-holder .template-box-5 .content-wrapper .heading {
  margin-bottom: auto;
  margin-top: 28px;
  text-align: center;
}

#content-holder .template-box-5 .content-wrapper .heading h2 {
  color: #ffe600;
  font-size: 38px;
}

@media (min-width: 576px) {
  #content-holder .template-box-5 .content-wrapper .heading h2 {
    font-size: 29.26px;
  }
}

@media (min-width: 768px) {
  #content-holder .template-box-5 .content-wrapper .heading h2 {
    font-size: 29.26px;
  }
}

@media (min-width: 992px) {
  #content-holder .template-box-5 .content-wrapper .heading h2 {
    font-size: 31.48px;
  }
}

@media (min-width: 1200px) {
  #content-holder .template-box-5 .content-wrapper .heading h2 {
    font-size: 38px;
  }
}

#content-holder .template-box-5 .content-wrapper .actions {
  margin-top: auto;
  margin-bottom: 28px;
  text-align: center;
}

#content-holder .template-box-5.games {
  background-color: transparent;
  background-size: cover !important;
}

#content-holder .template-box-5.games .content-wrapper .heading h2 {
  color: #0090d4;
}

#content-holder .template-box-5.music {
  background-color: #cce9f6;
  background-size: auto;
}

#content-holder .template-box-5.music .content-wrapper .heading h2 {
  color: #0090d4;
}

#content-holder .stripe {
  display: inline-block;
  background-image: url("/css/img/stripe.png");
  background-repeat: no-repeat;
  height: 4px;
  width: 20px;
  margin: 2px 0 5px;
}

body.page-12 #content-holder section.template-11 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text1,
body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text2,
body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text3,
body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text4 {
  font-size: 40px;
  line-height: 48px;
}

@media (min-width: 576px) {
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text1,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text2,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text3,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text4 {
    font-size: 35.42px;
    line-height: 42.35px;
  }
}

@media (min-width: 768px) {
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text1,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text2,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text3,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text4 {
    font-size: 35.42px;
    line-height: 42.35px;
  }
}

@media (min-width: 992px) {
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text1,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text2,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text3,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text4 {
    font-size: 39px;
    line-height: 45.65px;
  }
}

@media (min-width: 1200px) {
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text1,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text2,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text3,
  body.page-12 #content-holder section.template-11 NOT.container > .row .prb_text4 {
    font-size: 40px;
    line-height: 48px;
  }
}

footer {
  background-color: #cce9f6;
}

footer .container {
  padding-bottom: 30px;
}

footer .container img {
  margin-top: -35px;
}

@media (min-width: 768px) {
  footer .container img {
    margin-top: -45px;
    margin-left: -50px;
    transform: scale(0.77);
  }
}

@media (min-width: 992px) {
  footer .container img {
    transform: scale(0.83);
  }
}

@media (min-width: 1200px) {
  footer .container img {
    transform: scale(1);
  }
}

footer .container ul {
  margin-top: 28px;
  padding: 0;
  list-style-type: none;
}

footer .container ul.info,
footer .container ul.links a {
  color: #292929;
  font-size: 16px;
  line-height: 30px;
}

@media (min-width: 768px) {
  footer .container ul.info,
  footer .container ul.links a {
    font-size: 12.32px;
    line-height: 23.10px;
  }
}

@media (min-width: 992px) {
  footer .container ul.info,
  footer .container ul.links a {
    font-size: 13.28px;
    line-height: 24.90px;
  }
}

@media (min-width: 1200px) {
  footer .container ul.info,
  footer .container ul.links a {
    font-size: 16px;
    line-height: 30px;
  }
}

footer .container ul.info {
  font-weight: 400;
}

footer .container ul.links {
  margin-left: 20px;
}

@media (min-width: 576px) {
  footer .container ul.links {
    margin-left: 0px;
  }
}

footer .container ul.links li {
  padding-left: 1em;
  text-indent: -1em;
}

footer .container ul.links li:before {
  content: "»";
  padding-right: 7px;
}

footer .container ul.links li a {
  font-weight: 600;
}

footer .container ul.social-media {
  margin-top: 0px;
  margin-left: 78px;
}

@media (min-width: 576px) {
  footer .container ul.social-media {
    margin-top: 28px;
    margin-left: 0px;
  }
}

@media (min-width: 768px) {
  footer .container ul.social-media {
    transform: scale(0.77);
    margin-top: 21px;
    margin-left: -50px;
  }
}

@media (min-width: 992px) {
  footer .container ul.social-media {
    transform: scale(0.83);
    margin-top: 28px;
    margin-left: 0px;
  }
}

@media (min-width: 1200px) {
  footer .container ul.social-media {
    transform: scale(1);
    margin-top: 38px;
    margin-left: 0px;
  }
}

footer .container ul.social-media li {
  color: #292929 !important;
}

footer .container ul.social-media li a {
  display: inline-block;
  font-family: 'Cabin';
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  color: #292929 !important;
  width: 230px;
  text-align: left;
  padding: 0px 24px;
  border-radius: 24px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  white-space: nowrap;
  font-size: 18px;
  line-height: 40px !important;
}

@media (min-width: 576px) {
  footer .container ul.social-media li a {
    text-align: right;
  }
}

footer .container ul.social-media li a:hover {
  background-color: #f1f1f1;
  text-decoration: none;
}

footer .container ul.social-media li a i {
  display: block;
  float: left;
  font-size: 24px;
  color: #292929;
  padding-right: 15px;
  NOTmargin-top: 7px;
}

footer .container ul.social-media li a i.facebook {
  color: #3b5998;
}

footer .container ul.social-media li a i.pinterest {
  color: #bd081c;
}

footer > .container > .row > div:nth-child(2) {
  display: none;
}

@media (min-width: 576px) {
  footer > .container > .row > div:nth-child(2) {
    display: block;
  }
}

footer .bottom {
  display: block;
  text-align: right;
  margin-right: 20px;
}

@media (min-width: 768px) {
  footer .bottom {
    display: none;
  }
}

footer .footer-1 {
  display: none;
}

@media (min-width: 768px) {
  footer .footer-1 {
    display: block;
  }
}

@media (min-width: 768px) {
  .contactformulier .form-horizontal .control-label {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .contactformulier .form-horizontal .control-label {
    text-align: right;
  }
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-group .validator-message {
  color: #a94442;
  font-size: 12px;
  line-height: 14px;
}
