 @media (min-width: 768px) {
     .contactformulier .form-horizontal .control-label {
         text-align: left;
     }
 }
 @media (min-width: 992px) {
     .contactformulier .form-horizontal .control-label {
         text-align: right;
     }
 }
.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.form-group .validator-message {
    color: #a94442;
    font-size: 12px;
	line-height: 14px;
}
