.navbar {
	position: relative;
	font-weight: 600;
	@media (min-width: 2px) { //99
		padding: 0;
		.navbar-brand {
			padding: 0;
			margin: 0;
			position: absolute;
			top: 10px;
		}
	}
	.navbar-brand {
		top: 8px;
		img {
			height: 70px;
		}
		@media (min-width: 992px) {
			top: 8px;
			img {
				height: 70px;
			}
		}
		@media (min-width: 1200px) {
			left: 0;
			img {
				height: 75px;
			}
		}
	}
	.navbar-toggler {
		color: #0090d4;
		border-color: #0090d4;
		margin: 11px 0 12px;
		@media (min-width: 992px) {
			margin-top: -28px;
		}
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 144, 212, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
			&:hover {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 86, 179, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
			}
		}
		&:focus {
			outline: 0px;
		}
	}
	.navbar-collapse {
		margin: 32px 0 32px;
		@media (min-width: 992px) {
			margin: 0;
		}
	}
	.nav-item {
		.nav-link {
			padding: 10px 10px;
			@media (min-width: 992px) {
				padding: 19px 10px;
			}
			&:hover {
				color: #0056b3;
				text-decoration: none;
				i.fas {
					color: #0056b3;
				}
			}
		}
		&.dropdown {
			border-radius: 0;
			border-style: none solid;
			border-color: transparent;
			border-width: 0px;
			.nav-link {
				white-space: nowrap;
				.caret {
					display: inline-block;
					width: 0;
					height: 0;
					margin-left: 2px;
					vertical-align: middle;
					border-top: 7px dashed;
					border-right: 7px solid transparent;
					border-left: 7px solid transparent;
					@media (max-width: 992px) {
						width: 50px;
						height: 31px;
						border: none;
						font-family: 'Font Awesome 5 Pro';
						font-weight: 300;
						margin-left: 0px;
						margin-top: -6px;
						float: right;
						padding: 5px 20px;
						&:before {
							content: "\f067";
						}
					}
				}
			}
			&.show {
				border-color: #eaeaea;
				.nav-link {
					.caret {
						@media (max-width: 992px) {
							&:before {
								content: "\f068";
							}
						}
					}
				}
			}
			@media (min-width: 992px) {
				border-width: 1px;
			}

		}
		.dropdown-menu {
			background-color: transparent;
			box-shadow: none;
			border-radius: 0;
			border-width: 0;
			@media (min-width: 992px) {
				background-color: #ffffff;
				box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
				border-radius: 0 0 7px 7px;
				.dropdown-item:last-child {
					border-radius: 0 0 7px 7px;
				}
			}
		}
	}
	font-family: 'Cabin';
	font-size: 22px;
	@media (min-width: 576px) {

	}
	@media (min-width: 768px) {
		font-size: 16.94px;
	}
	@media (min-width: 992px) {
		font-size: 18.23px;
	}
	@media (min-width: 1200px) {
		font-size: 22px;
	}
	* {
		color: #0090d4;
	}
	.dropdown-menu {
		padding: 0;
		margin: -1px 0 0;
		border-radius: 0;
		border-top-style: none;
		border-color: #eaeaea;
		box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
		.dropdown-item {
			font-weight: 600;
			padding: 9px 18px;
			@media (min-width: 768px) {
				padding: 15px 18px;
			}
			&:hover,
			&:focus {
				color: #0056b3;
				text-decoration: none;
				background-color: transparent;
			}
			font-size: 20px;
			@media (min-width: 576px) {
			}
			@media (min-width: 768px) {
				font-size: 15.40px;
			}
			@media (min-width: 992px) {
				font-size: 16.57px;
				&:hover,
				&:focus {
					color: #ffffff;
					text-decoration: none;
					background-color: #0090d4;
				}
			}
			@media (min-width: 1200px) {
				font-size: 20px;
			}
		}
	}
	@media (min-width: 992px) {
		.navbar-nav .nav-link {
			padding-right: 20px;
			padding-left: 20px;
		}
		.collapse.navbar-collapse {
			
		}
	}
}


