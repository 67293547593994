footer {
    background-color: #cce9f6;
    .container {
        padding-bottom: 30px;
        img {
            margin-top: -35px;
            @media (min-width: 576px) {

            }
            @media (min-width: 768px) {
                margin-top: -45px;
                margin-left: -50px;
                transform: scale(0.77);
            }
            @media (min-width: 992px) {
                transform: scale(0.83);
            }
            @media (min-width: 1200px) {
                transform: scale(1);
            }
        }
        ul {
            margin-top: 28px;
            padding: 0;
            list-style-type: none;
            &.info,
            &.links a {
                color: #292929;
                font-size: 16px;
                line-height: 30px;
                @media (min-width: 576px) {

                }
                @media (min-width: 768px) {
                    font-size: 12.32px;
                    line-height: 23.10px;
                }
                @media (min-width: 992px) {
                    font-size: 13.28px;
                    line-height: 24.90px;
                }
                @media (min-width: 1200px) {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
            &.info {
                font-weight: 400;
            }
            &.links {
                margin-left: 20px;
                @media (min-width: 576px) {
                    margin-left: 0px;
                }
                li {
                    padding-left: 1em; text-indent: -1em;	
                    &:before {
                        content: "»"; padding-right:7px;
                    }
                    a {
                        font-weight: 600;
                    }
                }

            }
            &.social-media {
                margin-top: 0px;
                margin-left: 78px;
                @media (min-width: 576px) {
                    margin-top: 28px;
                    margin-left: 0px;
                }
                @media (min-width: 768px) {
                    transform: scale(0.77);
                    margin-top: 21px;
                    margin-left: -50px;
                }
                @media (min-width: 992px) {
                    transform: scale(0.83);
                    margin-top: 28px;
                    margin-left: 0px;
                }
                @media (min-width: 1200px) {
                    transform: scale(1);
                    margin-top: 38px;
                    margin-left: 0px;
                }
                li {
                    color: #292929 !important;
                    a {
                        display: inline-block;
                        font-family: 'Cabin';
                        background-color: #ffffff;
                        font-size: 16px;
                        font-weight: 600;
                        color: #292929 !important;
                        width: 230px;
                        text-align: left;
                        padding: 0px 24px;
                        border-radius: 24px;
                        box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
                        cursor: pointer;
                        white-space: nowrap;
                        font-size: 18px;
                        line-height: 40px !important;
                        @media (min-width: 576px) {
                            text-align: right;
                        }
                        @media (min-width: 768px) {
                        }
                        @media (min-width: 992px) {
                        }
                        @media (min-width: 1200px) {
                        }
                        &:hover {
                            background-color: #f1f1f1;
                            text-decoration: none;
                        }
                        i {
                            display: block;
                            float: left;
                            font-size: 24px;
                            color: #292929;
                            padding-right: 15px;
                            NOTmargin-top: 7px;
                            &.facebook {
                                color: #3b5998;
                            }
                            &.pinterest {
                                color: #bd081c;
                            }

                        }
                    }
                }
            }
        }
    }
    & > .container > .row {
        & > div:nth-child(2) {
            display: none;
        }
        @media (min-width: 576px) {
            & > div:nth-child(2) {
                display: block;
            }
        }
        @media (min-width: 768px) {
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1200px) {
        }
    }
    .bottom {
        display: block;
        text-align: right;
        margin-right: 20px;
        @media (min-width: 768px) {
            display: none;
        }
    }
    .footer-1 {
        display: none;
        @media (min-width: 768px) {
            display: block;
        }
    }
    .footer-5 {
    }
}
