body {
	font-family: 'Cabin', sans-serif;
	position: relative;
	background-color: #cce9f6;
}    

#content-holder {
	background-color: #ffffff;
	section {
		&:nth-child(1) {
			padding-top: 20px;
		}
	}
}
section.header {
	z-index: 2;
	position: relative;
	background-color: #ffffff;
	.eb-shadow {
		position: absolute;
		bottom: 0px;
		height: 5px;
		width: 100%;
		box-shadow: 0px 3px 5px 0px rgba(158,158,158,0.6);
	}
}

section.intro {
    background-color: #fff6a6;
    min-height: 300px;
}

@media (min-width: 576px) {
	.container {
		//max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		//max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		//max-width: 960px;
		//0.84210
	}
}

@media (min-width: 1200px) {
	.container {
		//max-width: 1140px;
	}
}

section.subheader {
	position: relative;
	background-color: #b4dfc4;
	.title {
		margin: 12px 0;
		text-align: center;
		position: relative;
		.eb-back {
			background-color: #ffffff;
			border-radius: 18px;
			width: 36px;
			height: 36px;
			text-align: center;
			position: absolute;
			left: 0px;
			top: 1px;
			font-weight: 400;
			color: #ffffff;
			font-size: 18px;
			line-height: 36px;
			color: #0090d4;
		}
		h1 {
			display: inline;
			margin: 0px;
			font-family: MikadoBlack;
			font-weight: 400;
			color: #ffffff;
			font-size: 30px;
			line-height: 36px;
		}
	}
	.action {

	}
	.eb-shadow {
		position: absolute;
		bottom: 0px;
		height: 5px;
		width: 100%;
		box-shadow: 0px 3px 5px 0px rgba(158,158,158,0.6);
	}
}
body.movie,
body.movie-item,
body.music,
body.music-item,
body.puzzle,
body.paint,
body.games,
body.print_material {
	section.item {
		background-color: #fff6a6;
	}
	section.items {
		background-color: #fff6a6;
		padding-bottom: 32px;
		@media (min-width: 768px) {
			padding-bottom: 70px;
		}
		.title {
			font-family: MikadoBlack;
			font-weight: 400;
			color: #f47920;
			font-size: 30px;
			line-height: 50px;
			text-align: center;
		}
		.container {
			.row {
				.square-box{
					position: relative;
					width: 100%;
					overflow: hidden;
					margin-top: 32px;
					background-color: #ffffff;
					box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
					border-radius: 7px;
					overflow: hidden;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}
				.square-box:before{
					content: "";
					display: block;
					padding-top: 100%;
				}
				.square-content{
					position:  absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					color: white;
					padding: 20px;
					.sample {
						width: 100%;
						height: 100%;
						text-align: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						display: flex;
						justify-content: center;
						flex-direction: column;
						text-align: center;
						.opacity {
							position:  absolute;
							width: 100%;
							height: 100%;
							background-color: #ffffff;
							opacity: 0.8;
						}
						.play {
							span {
								display: inline-block;
								z-index: 2;
								width: 50px;
								height: 50px;
								border-radius: 25px;
								background-color: #ffffff;
								text-align: center;
								font-size: 20px;
								line-height: 50px;
								box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
								i {
									color: #0090d4
								}
							}
						}
						.download {
							z-index: 2;
						}
						.opacity,
						.download {
							display: none;
						}
						&:hover {
							.opacity,
							.download {
								display: block;
							}
						}
					}

					.paint {
						position:  absolute;
						right: 10px;
						bottom: 10px;
						box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
						border-radius: 21px;
						width: 42px;
						height: 42px;
						background-color: #ffffff;
						font-family: 'Font Awesome 5 Pro';
						font-weight: 300;
						color: #63c29d;
						font-size: 21px;
						line-height: 42px;
						text-align: center;
					}
				}
			}
		}
	}
}
body.music-item {
	.items {
		.music-container {
			margin: 50px 20px 0 0 !important;
			@media (min-width: 992px) {
				margin: 50px 40px 0 0 !important;
			}
		}
		.music-items-container {
			margin-top: 50px;
		}
		#footerControls {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 25px 0 10px;
			a {
				display: inline-block;
				background-color: #ffffff;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				text-align: center;
				margin: 0 7px 0 0;
				@media (min-width: 768px) {
				}
				@media (min-width: 992px) {
					margin: 0 25px 0 0;
				}
				cursor: pointer;
				&.lgControl {
					width: 62px;
					height: 62px;
					border-radius: 31px;
					i {
						color: #0090d4;
						font-size: 24px;
						line-height: 62px;
					}
				}
				i {
					color: #0090d4;
					font-size: 24px;
					line-height: 50px;
				}
			}
		}
		#footerTitle {
			font-family: MikadoBlack;
			font-weight: 400;
			color: #f47920;
			font-size: 24px;
			line-height: 50px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			min-width: 0;
			text-align: center;
		}
		.item {
			display: flex;
			flex-direction: row;
			background-color: #ffffff;
			border-radius: 25px;
			margin-bottom: 13px;
			.left {
				flex-grow: 1;
				margin: 0 30px;
				min-width: 0;
				.jp-seek-bar {
					display: none;
					border-radius: 12px;
					//border: 1px solid #000;
					//background: none;
					background-color: #b2ddf2;
					//width: 150px !important;
					height: 24px;
					margin: 13px 0;
					//position: absolute;
					//left: 70px;
					//top: 24px;
					z-index: 100;
					overflow: hidden;
				}
				.jp-play-bar {
					border-radius: 0px;
					background: none;
					background-color: #0090d4;
					width: 6px;
					//min-width: 8px !important;
					height: 24px;
					cursor: pointer;
				}
	
			}
			.title {
				font-family: MikadoBlack;
				font-weight: 400;
				color: #f47920;
				font-size: 24px;
				line-height: 50px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				min-width: 0;
				text-align: left;
			}
			.controls {
				color: #0090d4;
				font-weight: 400;
				font-size: 24px;
				line-height: 50px;
				margin: 0 30px 0 0;
				i {
					cursor: pointer;
				}
				min-width: 0;
			}
		}
	}
	.items-jplayer {
		.music-container {
			margin: 50px 40px 0 0 !important;
		}
		.music-items-container {
			margin-top: 50px;
		}
		.item {
			display: flex;
			flex-direction: row;
			background-color: #ffffff;
			border-radius: 25px;
			margin-bottom: 13px;
			.left {
				flex-grow: 1;
				margin: 0 30px;
				min-width: 0;
				.jp-seek-bar {
					display: none;
					border-radius: 12px;
					//border: 1px solid #000;
					//background: none;
					background-color: #b2ddf2;
					//width: 150px !important;
					height: 24px;
					margin: 13px 0;
					//position: absolute;
					//left: 70px;
					//top: 24px;
					z-index: 100;
					overflow: hidden;
				}
				.jp-play-bar {
					border-radius: 0px;
					background: none;
					background-color: #0090d4;
					width: 6px;
					//min-width: 8px !important;
					height: 24px;
					cursor: pointer;
				}
	
			}
			.title {
				font-family: MikadoBlack;
				font-weight: 400;
				color: #f47920;
				font-size: 24px;
				line-height: 50px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				min-width: 0;
				text-align: left;
			}
			.controls {
				color: #0090d4;
				font-weight: 400;
				font-size: 24px;
				line-height: 50px;
				margin: 0 30px 0 0;
				i {
					cursor: pointer;
				}
				min-width: 0;
			}
		}
	}
}
body.games,
body.movie,
body.movie-item {
	section.items {
		.container {
			.row {
				.rectangle-box{
					position: relative;
					width: 100%;
					overflow: hidden;
					margin-top: 32px;
					background-color: #ffffff;
					box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
					border-radius: 7px;
					overflow: hidden;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}
				.rectangle-box:before{
					content: "";
					display: block;
					padding-top: 76%;
				}
				.rectangle-content{
					position:  absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					display: flex;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					.image {
						flex-grow: 1;
						min-height: 0;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
						display: flex;
						justify-content: center;
						flex-direction: column;
						text-align: center;
						.play {
							span {
								display: inline-block;
								z-index: 2;
								width: 50px;
								height: 50px;
								border-radius: 25px;
								background-color: #ffffff;
								text-align: center;
								font-size: 20px;
								line-height: 50px;
								box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
								i {
									color: #0090d4
								}
								@media (max-width: 576px) {
									width: 34px;
									height: 34px;
									border-radius: 17px;
									font-size: 12px;
									line-height: 34px;
								}
							}
						}
					}
					.title {
						font-family: MikadoBlack;
						font-weight: 400;
						color: #0090d4;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						margin: 0 10px;
						font-size: 13px;
						line-height: 30px;
						@media (min-width: 576px) {
						}
						@media (min-width: 768px) {
							font-size: 18.48px;
							line-height: 38.50px;
						}
						@media (min-width: 992px) {
						}
						@media (min-width: 1200px) {
							font-size: 19.92px;
							line-height: 41.50px;
						}
					}
				}
				.more-container {
					font-family: MikadoBlack;
					font-weight: 400;
					color: #0090d4;
					font-size: 19.92px;
					line-height: 41.50px;
					text-align: center;
					margin: 32px 0;
					cursor: pointer;
				}
				.video-container {
					position:relative;
					padding-bottom:56.25%;
					padding-top:30px;
					height:0;
					overflow:hidden;
					margin:50px 0 0 0;
				}
				.video-container iframe, 
				.video-container object, 
				.video-container embed,
				.video-container video {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
				}
			}
		}
	}
}
body.games {
	section.items {
		.container {
			.row {
				.rectangle-box:before {
					padding-top: 100%;
				}
				.rectangle-content {
					margin: 5px;
					@media (min-width: 576px) {
						margin: 15px;
					}
					@media (min-width: 768px) {
						margin: 15px;
					}
					@media (min-width: 992px) {
						margin: 20px;
					}
					@media (min-width: 1200px) {
					}
					.image {
						background-size: contain;
					}
					.title {
						margin: 0px;
						font-size: 15px;
						line-height: 1.2em;
						@media (min-width: 576px) {
							font-size: 19px;
						}
						@media (min-width: 768px) {
							font-size: 20px;
						}
						@media (min-width: 992px) {
							font-size: 24px;
						}
						@media (min-width: 1200px) {
							font-size: 24px;
						}
					}
				}
			}
		}
	}
}
body.puzzle section.items .container .row .square-box {
	&:before {
		padding-top: 83% !important;
	}
	.square-content .sample {
		background-size: cover;
	}
}
			